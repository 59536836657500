<template>
    <div>
        <b-container>
            <section v-if="getAdminEventGeneric.length == 0 && loader" class="event-details mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </section>
            <section v-else class="event-details">
                <b-row>
                    <b-col cols="12" lg="8">
                        <div class="sector">
                            <p class="event-details__type">{{ getAdminEvent.type.text }}</p>
                            <h2 class="event-details__title">{{ getAdminEvent.name }}</h2>
                            <div class="event-details__image" :style="{ backgroundImage: 'url(' + getAdminEvent.url + ')' }">
                                <div v-if="getAdminEvent.ribbon" class="custom-ribbon custom-ribbon--right"><span class="custom-ribbon__text custom-ribbon__text--red">Featured</span>
                                </div>
                            </div>
                        </div>
                        <div class="sector">
                            <h3 class="event-details__subtitle">Description</h3>
                            <p v-html="getAdminEvent.desc" class="event-details__text"></p>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="4">
                        <div class="sector">
                            <div class="event-details__element">
                                <h3 class="event-details__subtitle">Location</h3>
                                <span class="event-details__info"><simple-line-icons icon="locationPin" size="small" color="#504e70" />{{ getAdminEvent.address }} {{ getAdminEvent.city }}, {{ getAdminEvent.country.text }}</span>
                            </div>
                            <div class="event-details__element mb-0">
                                <h3 class="event-details__subtitle">Date</h3>
                                <span class="event-details__info"><simple-line-icons icon="calendar" size="small" color="#504e70" />{{ getAdminEvent.date }}</span>
                            </div>
                        </div>
                        <div v-if="getAdminEvent.email || getAdminEvent.phone || getAdminEvent.website" class="sector">
                            <div class="event-details__element mb-0">
                                <h3 class="event-details__subtitle">Contact</h3>
                                <span v-if="getAdminEvent.email" class="event-details__info"><simple-line-icons icon="envelope" size="small" color="#504e70" /><a class="event-details__link" :href="'mailto:'+ getAdminEvent.email">{{ getAdminEvent.email }}</a></span>
                                <span v-if="getAdminEvent.phone" class="event-details__info"><simple-line-icons icon="phone" size="small" color="#504e70" />{{ getAdminEvent.phone }}</span>
                                <span v-if="getAdminEvent.website" class="event-details__info"><simple-line-icons icon="globe" size="small" color="#504e70" /> <a class="event-details__link" :href="getAdminEvent.website">website</a></span>
                            </div>
                        </div>
                        <div class="sector">
                            <div class="event-details__element mb-0">
                                <h3 class="event-details__subtitle">Map location</h3>
                                <div class="event-details__map">
                                    <l-map :zoom="zoom" :center="[getAdminEvent.lat, getAdminEvent.lng]" :options="{zoomControl: false}">
                                    <l-tile-layer
                                    :url="url"
                                    layer-type="base"></l-tile-layer>
                                    <l-marker :lat-lng="[getAdminEvent.lat, getAdminEvent.lng]">
                                        <l-popup><b>{{ getAdminEvent.name }}</b><br> {{ getAdminEvent.address }},<br> {{ getAdminEvent.city }}, {{ getAdminEvent.country.text }}</l-popup>
                                    </l-marker>
                                    <l-control-zoom position="topright"></l-control-zoom>
                                    </l-map>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlZoom
} from 'vue2-leaflet'
export default {
  components: {
    SimpleLineIcons,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlZoom,
    Spinner
  },
  data: function () {
    return {
      loader: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16
    }
  },
  computed: {
    getAdminEventGeneric () {
      return this.$store.getters.getAdminEvent
    },
    getAdminEvent () {
      return this.$store.getters.getAdminEvent[0]
    }
  },
  beforeCreate () {
    this.$store.dispatch('getAdminEvent', { id: this.$route.params.id })
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
